import {
  addHours,
  differenceInHours,
  format,
  isAfter,
  isValid,
} from 'date-fns';
import dateFormat from 'dateformat';
import formatDate from 'dateformat';

import { DateTime } from 'luxon';
import {
  GeneratedCreateSubscriptionsInput,
  GeneratedEventArteProduct,
  GeneratedSubscriptionType,
} from '../../types/generated';
import { isBrowser } from '@utils/isBrowser';

export type EventStages = 'pre_event' | 'pre_live' | 'live' | 'pre_replay';

export const isEventWithinHours = (start: string, hours = 12): boolean => {
  const now = new Date();
  const hoursToStart = differenceInHours(new Date(start), now);
  return hoursToStart >= 0 && hoursToStart < hours;
};

export const eventHeading = (start: string, addYear?: boolean): string => {
  const localDate = new Date(start);
  const timeZone = DateTime.local().offsetNameShort;
  const dateFormat = `dd mmm ${addYear ? 'yyyy ' : ''}- HH:MM "${timeZone}"`;

  return formatDate(localDate, dateFormat);
};

export const formatTimelineDate = (date: string): string => {
  const localDate = new Date(date);
  const timeZone = DateTime.local().offsetNameShort;
  const dateFormat = `dddd, mmm dd - HH:MM "${timeZone}"`;
  return formatDate(localDate, dateFormat);
};

export const eventDateTime = (start: string): string => {
  return format(Date.parse(start), 'dd MMMM - HH:mm') + ' GMT';
};

export const formatCalendarDate = (datetime?: string): string | undefined => {
  const format = 'UTC:yyyy-mm-dd-"T"-HH-MM-ss-"Z"';
  if (datetime && isValid(new Date(datetime))) {
    return dateFormat(datetime, format).replace(/-|:/g, '');
  }
  return undefined;
};

export const parseEventDate = (startTime?: string, endTime?: string) => {
  let start = new Date().toJSON();
  if (startTime && isValid(new Date(startTime))) {
    start = startTime;
  }

  let end = addHours(new Date(start), 1).toJSON();
  if (endTime && isValid(new Date(endTime))) {
    end = endTime;
  }

  return { start, end };
};

export const getEventStage = (
  startTime: string,
  endTime: string,
): EventStages => {
  if (isBrowser()) {
    const params = new URLSearchParams(window.location.search);
    if (params.get('stage')) return params.get('stage') as EventStages;
  }

  if (endTime) return 'pre_replay';
  if (isAfter(new Date(), new Date(startTime))) return 'live';
  if (isEventWithinHours(startTime, 1)) return 'pre_live';
  return 'pre_event';
};

interface PartialEvent {
  artists: { id: string; name: string }[];
  products: GeneratedEventArteProduct[];
  slug: string;
  id: string;
}

export const getSubscriptionsForEvent = (
  event: PartialEvent,
): GeneratedCreateSubscriptionsInput[] => {
  const artistRefs = event.artists.map<GeneratedCreateSubscriptionsInput>(
    (a) => ({
      type: GeneratedSubscriptionType.Artist,
      objectId: a.id,
      ref: '',
      name: a.name,
    }),
  );

  const productRefs = event.products.map<GeneratedCreateSubscriptionsInput>(
    (p) => ({
      type: GeneratedSubscriptionType.Product,
      objectId: p.shopifyID ?? '',
      name: p.shopifyProduct?.name ?? '',
      ref: '',
    }),
  );

  const eventRef: GeneratedCreateSubscriptionsInput = {
    objectId: event.id,
    name: `Event - ${event.slug
      .split('-')
      .map((w) => w[0].toUpperCase() + w.substring(1))
      .join(' ')}`,
    type: GeneratedSubscriptionType.Event,
    ref: '',
  };

  return [...artistRefs, ...productRefs, eventRef];
};
