import React, { useMemo } from 'react';

import type { ImageSizes } from '../../helpers/images';
import { Franchises } from '../../types/Franchises';
import { capitalize } from '../../utils/strings';
import BannerShell from './BannerShell';
import type { BannerAnalytics, BannerLayout, StoryblokBannerV3 } from './types';

type Banner = StoryblokBannerV3<Franchises['content']>;

type Props = {
  analytics: BannerAnalytics;
  banner: Banner;
  className?: string;
  layout: BannerLayout;
  imageSizes: ImageSizes;
};

export const BannerInsights: React.FC<Props> = ({
  analytics,
  banner,
  className,
  imageSizes,
  layout,
}) => {
  const {
    overrideCategory,
    overrideDescription,
    overrideHeadline,
    overrideImages,
    overrideLabel,
    overrideVideo,
  } = banner;

  const { poster, subtitle, tag, title } = banner.link.story.content;

  const images = useMemo(() => {
    if (overrideImages?.length) {
      return overrideImages.slice(0, 3);
    }
    return poster ? [poster] : [];
  }, [overrideImages, poster]);

  const labels = useMemo(() => {
    let text = overrideLabel || capitalize(tag);
    return [{ active: false, loading: false, text }];
  }, [tag, overrideLabel]);

  return (
    <BannerShell
      analytics={{ ...analytics, journal: { title } }}
      category={overrideCategory || capitalize(tag)}
      className={className}
      description={overrideDescription || subtitle}
      headline={overrideHeadline || title}
      href={`/${banner.link.story.full_slug}`}
      images={images}
      imageSizes={imageSizes}
      labels={labels}
      layout={layout}
      video={overrideVideo?.vimeo_oembed && overrideVideo}
    />
  );
};
