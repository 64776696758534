import React from 'react';
import type { ImageSizes } from '../../helpers/images';
import type { BannerAnalytics, BannerLayout, StoryblokBannerV3 } from './types';
import BannerShell from './BannerShell';

type Props = {
  banner: StoryblokBannerV3;
  layout: BannerLayout;
  className?: string;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
};

const BannerLink: React.FC<Props> = ({
  banner,
  className,
  imageSizes,
  layout,
  analytics,
}) => {
  return (
    <BannerShell
      isExternal
      className={className}
      imageSizes={imageSizes}
      layout={layout}
      href={useBannerHref(banner)}
      images={useBannerImages(banner)}
      video={useBannerVideo(banner)}
      category={useBannerCategory(banner)}
      headline={useBannerHeadline(banner)}
      description={useBannerDescription(banner)}
      labels={useBannerLabels(banner)}
      analytics={analytics}
    />
  );
};

export default BannerLink;

export const useBannerHref = (banner: StoryblokBannerV3): string => {
  return banner.link.url;
};

export const useBannerVideo = (banner: StoryblokBannerV3) => {
  return banner.overrideVideo?.vimeo_oembed ? banner.overrideVideo : undefined;
};

export const useBannerImages = (
  banner: StoryblokBannerV3,
): StoryblokImage[] => {
  return banner.overrideImages?.slice(0, 3) || [];
};

export const useBannerCategory = (banner: StoryblokBannerV3): string => {
  return banner.overrideCategory || '';
};

export const useBannerHeadline = (banner: StoryblokBannerV3): string => {
  return banner.overrideHeadline || '';
};

export const useBannerDescription = (
  banner: StoryblokBannerV3,
): StoryblokTextAreaType => {
  return banner.overrideDescription || '';
};

export const useBannerLabels = (banner: StoryblokBannerV3) => {
  return banner.overrideLabel
    ? [{ active: false, loading: false, text: banner.overrideLabel }]
    : [];
};
