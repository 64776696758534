import classNames from 'classnames';
import type { ImageSizes } from '../../../helpers/images';
import { Image } from '../../Image';
import BannerInfo from './BannerInfo';
import { BannerLabel } from '../types';

type Props = {
  layout: 'SPOTLIGHT' | 'ARTIST';
  imageSizes: ImageSizes;
  labels: BannerLabel[];
  category: string;
  headline: string;
  description: string;
  image: StoryblokImage;
};

const BannerTile: React.FC<Props> = ({
  layout,
  imageSizes,
  labels,
  category,
  headline,
  description,
  image,
}) => {
  return (
    <div className="group relative grid grid-cols-2 gap-5">
      <div
        className={classNames(
          'rounded-sm overflow-hidden',
          layout === 'ARTIST' ? 'col-span-2 lg:col-span-1' : 'col-span-2',
        )}
      >
        <Image
          alt={image.alt}
          focus={image.focus}
          hoverEffect
          src={image.filename}
          size={imageSizes}
        />
      </div>
      <div
        className={classNames('relative', {
          'col-span-2 lg:col-span-1': layout === 'ARTIST',
          'col-span-2': layout === 'SPOTLIGHT',
        })}
      >
        <BannerInfo
          labels={labels}
          category={category}
          headline={headline}
          description={description}
        />
      </div>
    </div>
  );
};

export default BannerTile;
