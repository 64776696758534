import { useEffect, useState } from 'react';
import { ShopifyProductsController } from '../types/Home';
import { captureError } from '../utils/capture';
import fetchProductsByIds from '../utils/shopify/fetchProductsByIds';
import { useCurrency } from './useCurrency';

type Props = {
  identifiers: string[];
};

export const useShopifyProducts = ({
  identifiers,
}: Props): ShopifyProductsController => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currency] = useCurrency();

  useEffect(() => {
    if (identifiers.length === 0) return;

    (async () => {
      setLoading(true);
      try {
        const products = await fetchProductsByIds(
          identifiers,
          currency,
          'Admin',
        );
        setProducts(products);
      } catch (error) {
        captureError(error);
      }
      setLoading(false);
    })();
  }, []);

  return {
    products,
    loading,
  };
};
