import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import {
  TrackCarouselSlide,
  TrackLinkClicked,
  TrackMultiMediaClick,
} from '../../../utils/analytics';
import { getArtistTileOverline } from '../../../utils/artists';
import { storyblokMediaItemToLightboxEntry } from '../../../utils/lightbox';
import { Carousel } from '@design-system';
import VideoCard from '../../VideoCard';
import {
  Icon,
  Lightbox,
  LightboxController,
  LightboxItem,
} from '@design-system';
import { VimeoPlugin } from '../../../types/Vimeo';
import ArtistTile from '../ArtistTile';

type ArtistVideo = {
  coverImage: StoryblokImage;
  vimeo: VimeoPlugin;
  artist: ArtistPageContent;
  artistMetadata: {
    totalWorks: number;
    hideCollaborations: boolean;
  };
};

type Props = {
  headline: string;
  perPage: number;
  videos: ArtistVideo[];
  analytics: Analytics;
  withSeeAll?: boolean;
};

const ArtistVideosCarousel: React.FC<Props> = (props) => {
  const lightboxItems = useMemo(
    () => (props.videos as any[]).map(storyblokMediaItemToLightboxEntry),
    [props.videos],
  );

  return (
    <Lightbox items={lightboxItems as LightboxItem[]}>
      {(lightbox) => <VideoCarousel lightbox={lightbox} {...props} />}
    </Lightbox>
  );
};

export default ArtistVideosCarousel;

const VideoCarousel: React.FC<
  Props & {
    lightbox: LightboxController;
  }
> = ({ headline, videos, analytics, perPage, lightbox, withSeeAll }) => {
  const { t } = useTranslation('artists');

  const carouselItems = useMemo(() => {
    const items = [];

    videos.forEach((video, index) => {
      items.push(
        <VideoCarouselItem
          video={video}
          analytics={analytics}
          onClick={() => {
            TrackMultiMediaClick({
              page: analytics.page,
              context: analytics.context,
              pageTitle: analytics.pageTitle,
              type: 'video',
              title: video.vimeo?.vimeo_oembed.response.title,
              durationInSeconds: video.vimeo?.vimeo_oembed.response.duration,
              position: index,
            });
            lightbox.openOnSlide(index);
          }}
        />,
      );
    });

    if (withSeeAll) {
      items.push(
        <SeeAllCarouselItem
          onClick={() => {
            TrackLinkClicked({
              page: analytics.page,
              context: analytics.context,
              title: t('viewAllStudioVisits'),
              url: 'https://youtube.com/c/AvantArte',
              isExternal: true,
            });
          }}
        />,
      );
    }

    return items;
  }, [videos, analytics, withSeeAll]);

  return (
    <Carousel
      headline={headline}
      perPage={perPage}
      items={carouselItems}
      onSlide={(idx) => {
        analytics &&
          TrackCarouselSlide({
            page: analytics.page,
            context: analytics.context,
            title: analytics.context,
            position: idx,
            carouselLength: videos.length,
          });
      }}
    />
  );
};

const VideoCarouselItem: React.FC<{
  analytics: Analytics;
  video: ArtistVideo;
  onClick: () => void;
}> = ({ video, onClick, analytics }) => {
  const { t } = useTranslation('artists');

  return (
    <section>
      <VideoCard
        video={video.vimeo}
        coverImage={video.coverImage}
        onClick={onClick}
      />

      <div className="pt-4">
        <ArtistTile
          artist={{
            name: video.artist?.name,
            slug: video.artist?.full_slug,
            thumbnail: video.artist?.content?.thumbnail,
          }}
          page={analytics.page}
          overline={getArtistTileOverline(
            video.artistMetadata.totalWorks,
            video.artistMetadata.hideCollaborations,
            t,
          )}
        />
      </div>
    </section>
  );
};

const SeeAllCarouselItem: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('artists');

  return (
    <a
      href="https://youtube.com/c/AvantArte"
      target="_blank"
      onClick={onClick}
      className={classNames(
        'w-full aspect-[1/1] md:aspect-[3/2] rounded-sm hover:text-neutral-4 transition duration-300 ease-out flex justify-center items-center bg-neutral-1',
      )}
    >
      <div className="flex flex-col w-full justify-center items-center space-y-3">
        <div className="rounded-full bg-neutral-2 h-12 w-12 flex items-center justify-center">
          <Icon name="ic_arrow_top_right" size="md" />
        </div>
        <span className="text-center">{t('viewAllStudioVisits')}</span>
      </div>
    </a>
  );
};
