import { Pill } from '@design-system';
import { Fragment } from 'react';
import { renderStoryblokRichText } from '../../../utils/renderStoryblokRichText';
import { BannerLabel } from '../types';

type Props = {
  labels: BannerLabel[];
  category: string;
  headline: string;
  description: string;
};

const BannerInfo: React.FC<Props> = ({
  labels,
  category,
  headline,
  description,
}) => {
  return (
    <Fragment>
      <div className="flex flex-wrap gap-2 pb-3">
        {labels.map((label) => (
          <Pill
            key={label.text}
            $isActive={label.active}
            label={label.text}
            $isLoading={label.loading}
          />
        ))}
      </div>

      <div className="w-full">
        <div className="pb-2">
          <span className="inline text-md md:text-lg group-hover:text-neutral-4 group-focus:text-neutral-4 transition-colors duration-300 ease-out">
            {category}&nbsp;
          </span>
          <h3 className="inline font-bold text-md md:text-lg group-hover:text-neutral-4 group-focus:text-neutral-4 transition-colors duration-300 ease-out">
            {headline}
          </h3>
        </div>

        <div>
          <p className="line-clamp-3 group-hover:text-neutral-4 group-focus:text-neutral-4 transition-colors duration-300 ease-out">
            {renderStoryblokRichText(description, {
              paragraphClassName: 'pb-0',
            })}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default BannerInfo;
