import classNames from 'classnames';
import Link from 'next/link';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import SbEditable from 'storyblok-react';
import { HOME_PAGE_TYPE } from '../../constants';
import { pathFor } from '../../helpers/links';
import { HomeV2Data, HomeV2PageType } from '../../types/Home';
import { TrackCarouselSlide, TrackLinkClicked } from '../../utils/analytics';
import BannerV3 from '../BannerV3';
import { Carousel, ReviewCard } from '@design-system';
import SubscriptionBlock from '../Containers/SubscriptionBlock';
import ArtistVideosCarousel from '../Content/ArtistVideosCarousel';
import PageSection from '../Layout/PageSection';
import { LinkCardGroup } from '../LinkCards';
import { SupportFAQ } from '../Support/SupportFAQ/SupportFAQ';
import { AuthEntryPoint } from '../../features/auth/types';
import { GridCarousel } from '@design-system';

type Props = {
  blok: HomeV2PageType;
  data: HomeV2Data;
};

const HomeV2: React.FC<Props> = ({
  blok,
  data: {
    artistCollaborationStatuses,
    productArtistNames,
    productCountsByMedium,
    studioVisits,
    reviews,
    draws,
  },
}) => {
  const { t } = useTranslation(['homev2']);

  const firstLinkCards = blok.linkCardGroup[0];
  const secondLinkCards = blok.linkCardGroup[1];

  const artistBanners = useMemo(() => {
    return blok.bannerArtists.map((banner, idx) => (
      <div key={banner._uid} className="col-span-12 md:col-span-6">
        <BannerV3
          layout="ARTIST"
          banner={banner}
          imageSizes={{
            sm: { ratio: '4/3', span: 12 },
            md: { ratio: '4/3', span: 6 },
            lg: { ratio: '4/3', span: 3 },
          }}
          artistCollaborationStatuses={artistCollaborationStatuses}
          productArtistNames={productArtistNames}
          draws={draws}
          analytics={{
            page: 'home',
            type: 'artist',
            position: idx,
          }}
        />
      </div>
    ));
  }, [blok.bannerArtists]);

  const heroBanners = useMemo(() => {
    return blok.bannerHero.slice(0, 1);
  }, [blok.bannerHero]);

  return (
    // @ts-expect-error require storyblok-react upgrade
    <SbEditable content={blok}>
      <main className="space-y-12 md:space-y-15 my-9 md:my-12">
        {/* Header */}
        <PageSection>
          <div className="space-y-6">
            <h1
              className={classNames(
                'text-home-mobile md:text-home text-center mx-auto max-w-lg',
              )}
            >
              {t('header.title')}
            </h1>
            <section className="group flex flex-col md:flex-row flex-wrap justify-center divide-y divide-divider/20 md:divide-none">
              <HeaderMediumLink
                title={t('header.mediums.prints')}
                count={productCountsByMedium.prints}
                href={pathFor({
                  page: 'WORKS_INDEX',
                  filters: { medium: 'Print editions' },
                })}
              />
              <HeaderMediumLink
                title={t('header.mediums.sculptures')}
                count={productCountsByMedium.sculptures}
                href={pathFor({
                  page: 'WORKS_INDEX',
                  filters: { medium: 'Sculpture editions' },
                })}
              />
              <HeaderMediumLink
                title={t('header.mediums.collectibles')}
                count={productCountsByMedium.collectibles}
                href={pathFor({
                  page: 'WORKS_INDEX',
                  filters: { medium: 'Collectibles' },
                })}
              />
              <HeaderMediumLink
                title={t('header.mediums.studioWorks')}
                count={productCountsByMedium.studioWorks}
                href={pathFor({
                  page: 'WORKS_INDEX',
                  filters: { medium: 'Studio works' },
                })}
              />
              <HeaderMediumLink
                title={t('header.mediums.all')}
                count={productCountsByMedium.all}
                href={pathFor({ page: 'WORKS_INDEX' })}
              />
            </section>
          </div>
        </PageSection>

        {/* Hero Banner */}
        {heroBanners.length > 0 && (
          <PageSection>
            {heroBanners.map((banner, idx) => (
              <BannerV3
                key={banner._uid}
                className="block"
                layout="HERO"
                banner={banner}
                artistCollaborationStatuses={artistCollaborationStatuses}
                productArtistNames={productArtistNames}
                draws={draws}
                imageSizes={{
                  sm: { ratio: '3/4', span: 12 },
                  md: { ratio: '3/2', span: 12 },
                  lg: { ratio: '9/4', span: 12 },
                }}
                analytics={{
                  page: 'home',
                  type: 'hero',
                  position: idx,
                }}
              />
            ))}
          </PageSection>
        )}

        {/* Link Cards - 1 */}
        {firstLinkCards && (
          <PageSection>
            <LinkCardGroup
              key={firstLinkCards._uid}
              analyticsPlacement={firstLinkCards.analyticsPlacement}
              cards={firstLinkCards.cards}
            />
          </PageSection>
        )}

        {/* Spotlight Banners */}
        <PageSection fullWidth>
          <div className="wrapper-website mb-4">
            <h2 className="text-neutral-4 text-md md:text-lg">
              {t('banners.spotlight')}
            </h2>
          </div>

          <GridCarousel
            gridCols={3}
            items={blok.bannerHighlights.map((banner, idx) => (
              <BannerV3
                key={banner._uid}
                banner={banner}
                layout="SPOTLIGHT"
                imageSizes={{
                  sm: { ratio: '1/1', span: 12 },
                  md: {
                    ratio: idx === 1 || idx === 4 ? '3/4' : '1/1',
                    span: 6,
                  },
                  lg: {
                    ratio: idx === 1 || idx === 4 ? '3/4' : '1/1',
                    span: 4,
                  },
                }}
                artistCollaborationStatuses={artistCollaborationStatuses}
                productArtistNames={productArtistNames}
                draws={draws}
                analytics={{
                  page: 'home',
                  type: 'spotlight',
                  position: idx,
                }}
              />
            ))}
          />
        </PageSection>

        {/* Artist Banners */}
        <PageSection fullWidth>
          <Carousel
            arrowPosition="middle"
            headline={t('banners.artists')}
            perPage={2}
            items={artistBanners}
          />
        </PageSection>

        {/* Studio Visits */}
        <PageSection fullWidth>
          <ArtistVideosCarousel
            withSeeAll
            headline={t('studioVisits.title')}
            perPage={2}
            videos={studioVisits}
            analytics={{
              page: 'home',
              pageTitle: 'Home',
              context: 'studio visits',
            }}
          />
        </PageSection>

        {/* Link Cards - 2 */}
        {secondLinkCards && (
          <PageSection>
            <LinkCardGroup
              key={secondLinkCards._uid}
              analyticsPlacement={secondLinkCards.analyticsPlacement}
              cards={secondLinkCards.cards}
            />
          </PageSection>
        )}

        {reviews.length > 0 && (
          <PageSection fullWidth>
            <Carousel
              headline={t('reviews.title')}
              perPage={4}
              items={reviews.map((review) => (
                <ReviewCard key={review.id} review={review} />
              ))}
              onSlide={(index) => {
                TrackCarouselSlide({
                  page: 'home',
                  context: 'reviews',
                  title: t('reviews.title'),
                  position: index,
                  carouselLength: reviews.length,
                });
              }}
            />
          </PageSection>
        )}

        {/* Sign up */}
        <PageSection>
          <SubscriptionBlock
            title={t('signUp.title')}
            description={t('signUp.description')}
            buttonLocation="homepage cta"
            backgroundImage={blok.ctaImage}
            source={AuthEntryPoint.NewsletterHomepage}
          />
        </PageSection>

        {blok.faq?.story?.content && (
          <PageSection>
            <SupportFAQ
              analytics={{
                page: 'home page',
                context: 'FAQ',
              }}
              faq={blok.faq?.story?.content}
              image={blok.faqImage}
              title={t('faq.title')}
            />
          </PageSection>
        )}
      </main>
    </SbEditable>
  );
};

export default HomeV2;

const HeaderMediumLink: React.FC<{
  title: string;
  href: string;
  count: number;
}> = ({ title, href, count }) => {
  return (
    <Link
      href={href}
      prefetch={false}
      onClick={() => {
        TrackLinkClicked({
          page: HOME_PAGE_TYPE,
          context: 'medium',
          title: title,
          url: href,
          isExternal: false,
        });
      }}
    >
      <span
        className={classNames(
          'flex justify-center items-start px-2 py-3 md:py-0',
          'hover:text-neutral-4 focus:text-neutral-4',
          'transition-colors duration-300',
        )}
      >
        <h2 className="text-home-mobile md:text-home font-bold text-center">
          {title}
        </h2>
        <span className="pl-2 text-center">{count}</span>
      </span>
    </Link>
  );
};
