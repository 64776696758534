import { useState, useEffect } from 'react';
import { isFuture } from 'date-fns';
import type { CountdownController, DestructuredDate } from '../types/Countdown';
import { getDestructuredDate } from '../components/ProductTile/utils/release';
import { useTime } from './useTime';

type Props = {
  date?: Date;
  addDaysToHours?: boolean;
};

const EMPTY_DESTRUCTURED_DATE: DestructuredDate = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

export const useCountdown = ({
  date,
  addDaysToHours,
}: Props): CountdownController => {
  const startDate = useTime();
  const [isOver, setIsOver] = useState(false);
  const [destructuredDate, setDestructuredDate] = useState<DestructuredDate>(
    EMPTY_DESTRUCTURED_DATE,
  );

  useEffect(() => {
    if (date && isFuture(date)) {
      setIsOver(false);
      setDestructuredDate(
        getDestructuredDate({ startDate, endDate: date, addDaysToHours }),
      );
    } else {
      setIsOver(true);
      setDestructuredDate(EMPTY_DESTRUCTURED_DATE);
    }
  }, [startDate, date?.getTime()]);

  return {
    destructuredDate,
    currentDate: startDate,
    isOver,
  };
};

export default useCountdown;
