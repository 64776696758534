import React, { ReactNode } from 'react';
import Link from 'next/link';
import ArtistThumbnail from '../ArtistThumbnail';
import { TrackLinkClicked } from '../../../utils/analytics';

type Props = {
  artist?: {
    name: string;
    slug: string;
    thumbnail: {
      filename: string;
      focus?: string;
    };
  };
  overline?: string | ReactNode;
  target?: '_blank' | '_self';
  thumbnailShape?: 'square' | 'circle';
  thumbnailSize?: 'xs' | 'sm' | 'md';
  page: string;
};

const ArtistTile: React.FC<Props> = ({
  artist,
  overline,
  target = '_self',
  thumbnailShape = 'square',
  thumbnailSize = 'sm',
  page,
}) => {
  return (
    (<Link
      href={`/${artist?.slug}`}
      prefetch={false}
      data-testid={`artist-tile-${artist?.name.toLowerCase()}`}
      className="group"
      onClick={() =>
        TrackLinkClicked({
          page: page ? page : '',
          context: 'artist tile',
          title: artist?.name || '',
          url: artist?.slug || '',
          isExternal: false,
        })
      }
      target={target}>

      <div className="flex align-items items-center space-x-3">
        <div className="shrink-0">
          <ArtistThumbnail
            name={artist?.name || page}
            thumbnail={artist?.thumbnail}
            thumbnailShape={thumbnailShape}
            size={thumbnailSize}
          />
        </div>
        <div className="flex-1 overflow-hidden">
          <div className="group-hover:text-neutral-4 theme-dark:group-hover:text-neutral-3 transition duration-300 truncate">
            {artist?.name}
          </div>
          {overline && (
            <div className="text-neutral-4 theme-dark:text-neutral-3 truncate">
              {overline}
            </div>
          )}
        </div>
      </div>

    </Link>)
  );
};

export default ArtistTile;
