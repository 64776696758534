import { formatDistanceStrict } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

import type { ImageSizes } from '../../helpers/images';
import { useCountdown } from '../../hooks/useCountdown';
import {
  eventHeading,
  getEventStage,
  isEventWithinHours,
} from '../../utils/liveEvents/liveEvents';
import BannerShell from './BannerShell';
import type {
  BannerAnalytics,
  BannerLabel,
  BannerLayout,
  StoryblokBannerV3,
} from './types';

const useBannerImages = (
  banner: StoryblokBannerV3<EventContentType>,
): StoryblokImage[] => {
  return useMemo(
    () =>
      banner.overrideImages?.length
        ? banner.overrideImages.slice(0, 1)
        : [banner.link.story.content.image],
    [banner],
  );
};

export const useBannerLabels = (
  startTime: string,
  endTime: string,
  overrideLabel?: string,
): BannerLabel[] => {
  const { t } = useTranslation(['product', 'banner']);
  const stage = getEventStage(startTime, endTime);

  const { destructuredDate, isOver: countDownIsOver } = useCountdown({
    addDaysToHours: false,
    date: new Date(startTime),
  });

  if (overrideLabel) {
    return [{ active: false, loading: false, text: overrideLabel }];
  }

  if (stage === 'pre_event' || stage === 'pre_live') {
    const upcoming = isEventWithinHours(startTime, 12)
      ? t('banner:event.available_in', destructuredDate)
      : eventHeading(startTime);
    return [
      {
        active: countDownIsOver,
        loading: false,
        text: countDownIsOver ? t('banner:event.live') : upcoming,
      },
    ];
  }

  if (stage === 'live') {
    return [
      {
        active: true,
        loading: false,
        text: t('banner:event.live'),
      },
    ];
  }

  const from = new Date(endTime || startTime);
  const time = formatDistanceStrict(from, new Date());

  return [
    {
      active: false,
      loading: false,
      text: t('banner:event.post_live', { time }),
    },
  ];
};

type Props = {
  banner: StoryblokBannerV3<EventContentType>;
  className?: string;
  layout: BannerLayout;
  imageSizes: ImageSizes;
  analytics: BannerAnalytics;
};

export const BannerEvent: React.FC<Props> = ({
  analytics,
  banner,
  className,
  imageSizes,
  layout,
}) => {
  const { content } = banner.link.story;
  const {
    overrideCategory,
    overrideDescription,
    overrideHeadline,
    overrideLabel,
  } = banner;

  return (
    <BannerShell
      analytics={analytics}
      category={overrideCategory || content.subtitle}
      className={className}
      description={overrideDescription || content.description}
      headline={overrideHeadline || content.title}
      href={banner.link.story.full_slug}
      images={useBannerImages(banner)}
      imageSizes={imageSizes}
      labels={useBannerLabels(
        content.startTime,
        content.endTime,
        overrideLabel,
      )}
      layout={layout}
    />
  );
};
