import { lpad } from '../../utils/strings';
import { Image } from '../Image';
import { VideoDuration } from './VideoDuration';
import { VimeoPlugin } from '../../types/Vimeo';
import { Icon } from '@design-system';

type Props = {
  coverImage: StoryblokImage;
  video: VimeoPlugin;
  onClick?: () => void;
};

const VideoCard: React.FC<Props> = ({ coverImage, video, onClick }) => {
  return (
    <div
      className="rounded-sm overflow-hidden relative group cursor-pointer"
      onClick={onClick}
    >
      <Image
        alt={coverImage.alt}
        focus={coverImage.focus}
        hoverEffect
        src={coverImage.filename}
        size={{
          sm: { ratio: '1/1', span: 12 },
          md: { ratio: '3/2', span: 6 },
          lg: { ratio: '3/2', span: 6 },
        }}
      />
      <div className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-dark/30 group-hover:bg-dark/0 transition duration-300 ease-out">
        {video?.vimeo_oembed?.response?.duration && (
          <VideoDuration
            duration={formatSecondsDuration(
              video.vimeo_oembed?.response?.duration,
            )}
          />
        )}

        <div className="bg-white text-neutral-6 rounded-full">
          <Icon name="ic_play" size="md" className="m-3" />
        </div>
      </div>
    </div>
  );
};

export default VideoCard;

export const formatSecondsDuration = (total: number) => {
  const minutes = lpad(Math.floor(total / 60).toString(), '0', 2);
  const seconds = lpad((total % 60).toString(), '0', 2);

  return `${minutes}:${seconds}`;
};
