import { intervalToDuration } from 'date-fns';
import { DestructuredDate } from '../../../types/Countdown';
import { lpad } from '../../../utils/strings';

export const getDestructuredDate = ({
  startDate,
  endDate,
  addDaysToHours = false,
}: {
  startDate: Date;
  endDate: Date;
  addDaysToHours?: boolean;
}): DestructuredDate => {
  const {
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  const totalDays = addDaysToHours ? 0 : days;
  const totalHours = addDaysToHours ? hours + days * 24 : hours;

  return {
    days: lpad(totalDays.toString(), '0', 2),
    hours: lpad(totalHours.toString(), '0', 2),
    minutes: lpad(minutes.toString(), '0', 2),
    seconds: lpad(seconds.toString(), '0', 2),
  };
};
